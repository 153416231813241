<template>
  <div class="content">
    <!-- 大图片 -->
    <div class="box_1 flex-col">
      <div class="box_2 flex-col">
        <span class="text_10">渠道合作</span>
        <span class="text_11">专注建筑能源领域，携手扑沃共拓市场！</span>
        <div class="box_4 flex-col"></div>
      </div>
    </div>
    <div class="icon_box">
      <div class="icon_box_item" v-for="(item, index) in desList" :key="index">
        <img :src="item.imgurl" alt="" />
        <div class="des_cls">{{ item.des }}</div>
        <div class="des_cls1">{{ item.des1 }}</div>
        <div class="des_cls2">{{ item.des2 }}</div>
      </div>
    </div>
    <div class="group_14 flex-row justify-between">
      <div class="group_1 flex-col"></div>
      <div class="text-wrapper_25 flex-col justify-between">
        <span class="text_17">渠道合作</span>
        <span class="text_18">Cooperation</span>
      </div>
    </div>
    <div class="cooperation">
      <div class="c_left_box">
        <div class="msg_line1">您可以在这里向我们了解关于扑沃以及合作事宜</div>
        <div class="msg_line2">
          <div class="line2_item">
            联系电话：<br />
            021-39882683
          </div>
          <div class="line2_item">
            全国热线：<br />
            177 2132 3870
          </div>
          <div class="line2_item">
            江浙沪热线：<br />
            13918812305
          </div>
        </div>
        <div class="msg_line3">
          公司邮箱：<br />
          puwo888@163.com
        </div>
        <div class="msg_line4">
          公司地址：<br />
          上海市奉贤区浦星公路9688弄6号6幢二层
        </div>
      </div>
      <div class="c_right_box">
        <div class="form_box">
          <el-form
            :model="formdata"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <div class="right_line1">
              <el-form-item prop="name" class="line1_item">
                <el-input
                  v-model="formdata.name"
                  placeholder="请输入您的姓名"
                ></el-input>
              </el-form-item>
              <el-form-item prop="phone" class="line1_item">
                <el-input
                  v-model="formdata.phone"
                  placeholder="请输入您的电话"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item prop="email">
              <el-input
                v-model="formdata.email"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>

            <el-form-item prop="remark">
              <el-input
                v-model="formdata.remark"
                type="textarea"
                :rows="6"
                placeholder="请填写您的内容"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button @click="sumbit" style="float: left">提 交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/contact/c_icon1.png";
import img2 from "@/assets/contact/c_icon2.png";
import img3 from "@/assets/contact/c_icon3.png";
import img4 from "@/assets/contact/c_icon4.png";
import img5 from "@/assets/contact/c_icon5.png";
export default {
  data() {
    return {
      desList: [
        {
          imgurl: img1,
          des: "企业实力雄厚",
          des1: "500+客户",
          des2: "60+行业生态合作伙伴",
        },
        {
          imgurl: img2,
          des: "产品技术领先",
          des1: "150+技术团队实力见证",
          des2: "场景化SaaS",
        },
        {
          imgurl: img3,
          des: "丰富的市场资源",
          des1: "多渠道整体营销推广",
          des2: "标杆案例宣传、高端峰",
        },
        {
          imgurl: img4,
          des: "本地化服务",
          des1: "城市运营中心",
          des2: "随时提供本地化服务",
        },
        {
          imgurl: img5,
          des: "培训认证持续赋能",
          des1: "全线产品及服务培训",
          des2: "运营能力、市场销售集",
        },
      ],
      formdata: {
        email: "",
        name: "",
        phone: "",
        remark: "",
        companyname: "",
        profession: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "请输入正确的手机号码",
          },
        ],
        email: [{ required: true, message: "请输入您的邮箱", trigger: "blur" }],
        remark: [{ required: true, message: "请输入留言", trigger: "blur" }],
      },
      submitURL: window.gurl.URL + "customerinfo/submit",
    };
  },
  mounted() {
    // this.getIndexInfo();
  },

  methods: {
    async sumbit() {
      const res1 = await this.axios.post(this.submitURL, {
        ...this.formdata,
      });
      if (res1.rescode == 200) {
        this.$message.success({
          message: "提交成功，请等待客服联系",
          offset: 120,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  background-color: #fff;
}
.toptu {
  width: 100%;
  height: 340px;
  background: no-repeat top left;
  background-size: 100% 100%;
  position: relative;
  background-image: url(../assets/image/cooperationbg.jpg);
  margin-top: 92px;
  .page_title {
    position: absolute;
    font-size: 44px;
    letter-spacing: 5px;
    color: #ffffff;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.el-button {
  width: 200px;
  height: 50px;
  border-radius: 0;
  color: #fff;
  background: #3439c0;
  border-radius: 23px;
}
::v-deep .el-input__inner {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background-color: #f3f4f5;
}
::v-deep .el-textarea__inner {
  background-color: #f3f4f5;
}

::v-deep .el-select {
  width: 260px;
  height: 50px;
  .el-input__inner {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
  }
  .el-input__inner:focus {
    border-color: transparent;
  }
}

// 新版css
.box_1 {
  background-image: url(../assets/contact/contactbg.png);
  height: 24.9vw;
  border: 1px solid rgba(151, 151, 151, 1);
  width: 100vw;
}

.box_2 {
  // background-color: rgba(24, 59, 124, 0.55);
  width: 100vw;
  height: 24.9vw;
}

.text_10 {
  width: 6.67vw;
  height: 2.35vw;
  overflow-wrap: break-word;
  color: rgba(212, 222, 239, 1);
  font-size: 1.66vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.35vw;
  margin: 8.32vw 0 0 12.5vw;
}

.text_11 {
  width: 42.45vw;
  height: 3.29vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 2.39vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 3.29vw;
  margin: 0.36vw 0 0 12.5vw;
}

.box_4 {
  background-color: rgba(255, 123, 52, 1);
  width: 9.38vw;
  height: 0.21vw;
  margin: 1.35vw 0 8.33vw 12.5vw;
}

.icon_box {
  width: 75vw;
  margin: 3.9vw auto;
  display: flex;
  .icon_box_item {
    flex: 1;
    text-align: center;
    img {
      width: 2.5vw;
      height: 2.5vw;
    }
    .des_cls {
      color: #222222;
      line-height: 1.31vw;
      font-size: 0.93vw;
      letter-spacing: 0.44999998807907104px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      margin-bottom: 0.5vw;
    }
    .des_cls1,
    .des_cls2 {
      color: rgba(102, 102, 102, 1);
      font-size: 0.72vw;
      letter-spacing: 0.3499999940395355px;
      text-align: center;
      line-height: 1.05vw;
    }
  }
}

.group_14 {
  width: 14.43vw;
  height: 3.91vw;
  margin: 0vw 0 0 12.5vw;
}

.group_1 {
  background-color: rgba(52, 57, 192, 1);
  width: 0.21vw;
  height: 3.44vw;
  margin-top: 0.16vw;
}

.text-wrapper_25 {
  width: 13.29vw;
  height: 3.91vw;
}

.text_17 {
  width: 5.16vw;
  height: 1.72vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.25vw;
  letter-spacing: 0.6000000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 1.72vw;
}

.text_18 {
  width: 13.29vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 1.77vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.19vw;
}

.cooperation {
  width: 75vw;
  margin: 2vw auto 0;
  padding-bottom: 6vw;
  display: flex;
  .c_left_box {
    width: 40%;
    .msg_line1 {
      overflow-wrap: break-word;
      color: #000;
      font-size: 1.25vw;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 1.72vw;
    }
    .msg_line2 {
      display: flex;
      justify-content: space-between;
      margin-top: 1vw;
      .line2_item {
        overflow-wrap: break-word;
        color: rgba(45, 45, 45, 1);
        font-size: 0.93vw;
        font-family: Montserrat-Regular;
        font-weight: NaN;
        text-align: left;
        line-height: 1.51vw;
      }
    }
    .msg_line3,
    .msg_line4 {
      margin-top: 1vw;
      overflow-wrap: break-word;
      color: rgba(45, 45, 45, 1);
      font-size: 0.93vw;
      font-family: Montserrat-Regular;
      font-weight: NaN;
      text-align: left;
      line-height: 1.51vw;
    }
  }
  .c_right_box {
    width: 60%;
    .form_box {
      padding-left: 5vw;
      .right_line1 {
        display: flex;
        justify-content: space-between;
        .line1_item {
          width: 49%;
        }
      }
    }
  }
}
</style>
